import { render } from 'preact';
import { useEffect } from 'preact/hooks';
// @ts-ignore
import { exec } from 'preact-router';
import { getRedirectUrl } from 'lib-frontend-shared/src/helpers/auth';

import { get as getAccount } from '~/actions/account';
import useAsyncEffect from './helpers/useAsyncEffect';

import { useGlobalState } from './store';
import Router from './initialization/Router';
// import * as globalActions from '../actions/global';
import { checkAuthorization, redirectToLoginPage } from './actions/auth';
import { replace } from '~/actions/route';
import routes from './routes/routes';
import './logger';
import './main.css';

const gtagId = /** @type {string} */ (import.meta.env.VITE_APP_GTAG);

const accountFetcher = (accountId) => () => {
  if (accountId) getAccount(accountId);
};

const noLoginPatterns = routes
  .filter(({ noLoginNeeded }) => noLoginNeeded)
  .map((route) => route.path);

const getStarted = '/get-started';

const App = () => {
  // check auth
  const auth = useGlobalState('auth');
  const { accountId, authorizing, carriyoUserId } = auth;
  const { accountId: postLoginAccountId } = useGlobalState('account');

  useAsyncEffect(async () => {
    const loginAuthorized = await checkAuthorization();
    const loginRequired = !noLoginPatterns
      .some((routePattern) => exec(window.location.pathname, routePattern, {}));

    if (!loginAuthorized && loginRequired) {
      const redirectUrl = getRedirectUrl(window.location.href);
      redirectToLoginPage({ redirectUri: redirectUrl.href });
      return;
    }

    // force account setup (if not: /get-started or /shopify/get-started)
    const isGetStarted = window.location.pathname.endsWith(getStarted);
    // loginRequired check in the following line is needed for invitation flow route
    const notReady = !authorizing && !accountId && !isGetStarted && loginRequired;

    if (notReady) replace(getStarted);
  }, [
    accountId,
    authorizing,
    carriyoUserId,
    postLoginAccountId,
    window.location.pathname,
  ]);

  // useAsyncEffect(async () => {
  //   // preload some data
  //   if (accountId) {
  //     globalActions.preloadGccCountries();
  //     await globalActions.getUserDefinedEnums();
  //     await globalActions.getUserConfigs({});
  //     authActions.pollEnterpriseSSOSessionCheck();
  //   }
  //   // implicit dependency of getUserDefinedEnums() is accountId
  // }, [accountId]);

  useEffect(accountFetcher(accountId), [accountId]);

  useEffect(() => {
    if (!gtagId || typeof window === 'undefined') return;
    /* eslint-disable no-undef */
    gtag('js', new Date());
    gtag('config', gtagId);
    /* eslint-disable no-undef */
    const script = document.createElement('script');
    script.async = true;
    script.src = `https://www.googletagmanager.com/gtag/js?id=${gtagId}`;
    document.head.append(script);
    return () => script.remove();
  }, []);

  return <Router />;
};

render(
  <App />,
  // @ts-ignore
  document.getElementById('app')
);
