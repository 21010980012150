import { useErrorBoundary } from 'preact/hooks';
import PosterView from '../components/layout/PosterView';
import CrashPoster from '../images/crash.svg';
import LayoutDesktop from './LayoutDesktop';

const Layout = (props) => {
  /* TODO: log to kibana inside useErrorBoundary() */
  const [error] = useErrorBoundary((error) => {
    try {
      // @ts-ignore
      window.$logger.critical({
        message: `[PAGE_RENDER_ERROR] ${error.message}`,
        stack: error.stack,
      });
    } catch (err) {
      // DO NOT log with console.warn or console.error, to prevent infinite cyclic logging
      // @ts-ignore
      (console._error || console.debug)(err);
    }
  });

  if (error) {
    return (
      <PosterView
        imageUrl={CrashPoster}
        imageAlt="Unexpected error"
        header="Ooops! Something went wrong."
        footer={
          <>
            We apologize for the inconvenience.
            <br />
            Please contact our team at&nbsp;
            <a href="mailto:support@carriyo.com">support@carriyo.com</a>
            &nbsp;to report the issue.
          </>
        }
      />
    );
  }

  return <LayoutDesktop {...props} />;
};

export default Layout;
