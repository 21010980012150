export const getRedirectUrl = (urlString) => {
  const url = new URL(urlString);
  const returnTo = (
    url.searchParams.get('return_to')
    || `${url.pathname}${url.search}`
  );
  if (returnTo) {
    url.pathname = '/';
    url.search = '';
    url.searchParams.set('return_to', returnTo);
  }
  return url;
};
