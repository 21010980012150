import cls from '../../helpers/cls';
import Spinner from '~/components/icons/Spinner';
import './FormButton.css';

/**
 * @param {object} props
 * @param {import('preact').ComponentChildren} [props.children]
 * @param {string} [props.className]
 * @param {object} [props.style]
 * @param {'normal' | 'disabled' | 'ready' | 'waiting'} [props.state = 'normal']
 * @param {import('preact').JSX.MouseEventHandler<HTMLButtonElement>} props.onClick
 */
const FormButton = (props) => {
  const {
    children = undefined,
    className = undefined,
    corner = 'round',
    onClick = undefined,
    state = 'normal',
    style = undefined,
    width = 'full',
  } = props;

  const classes = cls('FormButton', { corner, state, width }, className);

  return (
    <button
      className={classes}
      disabled={state !== 'ready'}
      onClick={onClick}
      style={style}
    >
      {state === 'waiting' ? (
        <Spinner className="FormButton-spinner" />
      ) : (
        children
      )}
    </button>
  );
};

export default FormButton;
