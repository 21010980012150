import axios from 'axios';
import qs from 'qs';
import { identity } from 'lodash';
import { getStates } from '../store';
import { isLogoutRequested, logout } from '~/logout';

export const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
export const timeZoneOffset = -1 * new Date().getTimezoneOffset();

const appStartTime = (new Date()).toISOString();
let sessionStartTime = sessionStorage.getItem('sessionStartTime');
if (!sessionStartTime) {
  sessionStartTime = appStartTime;
  sessionStorage.setItem('sessionStartTime', sessionStartTime);
}

export function getBackendHeadersToAdd() {
  const {
    auth: { accessToken, accountId, accessByTenantId = {}, carriyoUserId },
  } = getStates();

  const { apiKey } = accessByTenantId[accountId] || {};

  return {
    ...(accountId ? { 'account-id': accountId, 'tenant-id': accountId } : {}),
    ...(accessToken ? { authorization: accessToken } : {}),
    ...(apiKey ? { 'x-api-key': apiKey } : {}),
    ...(carriyoUserId ? { 'x-user': carriyoUserId } : {}),
  };
}

export function getCacheBustHeaders() {
  return {
    'Cache-Control': 'no-cache, no-store',
    Pragma: 'no-cache', // hack for cache busting on safari
    Vary: `${Math.trunc(Math.random() * 100000)}`, // hack for cache busting on safari
  };
}

const oneMinuteInMs = 1 * 60 * 1000;

const proactiveLogoutInterceptor = (requestConfig) => {
  // @ts-ignore
  const { auth: { expiresAt } = {} } = getStates();
  if (expiresAt && Date.now() > expiresAt - oneMinuteInMs) {
    logout();
    throw new Error('Session expired');
  }
  return requestConfig;
};

const browserLogPath = '/public/logs/browser-log';

const reactiveLogoutInterceptor = (error) => {
  let url;
  try {
    url = error?.config?.url
      ? new URL(`${error.config.baseURL || ''}${error.config.url}`)
      : null;
  } catch (err) {
    // most likely a URL parsing error. Ignore and continue
  }
  if (
    error?.response?.status === 401 &&
    (!url || !url.pathname?.startsWith(browserLogPath)) // avoid 401 loop with browser logger
  ) {
    logout();
  }
  throw error;
};

export const carriyoClient = axios.create({
  // @ts-ignore
  baseURL: /** @type {string}*/ (import.meta.env.VITE_APP_BACKEND_BASE_URL),
  headers: {
    'content-type': 'application/json',
    'user-tz': timeZone,
    'x-app-start-time': appStartTime,
    'x-session-start-time': sessionStartTime,
  },
  paramsSerializer: (ps) => qs.stringify(ps, { arrayFormat: 'repeat' }),
});
carriyoClient.interceptors.request.use((requestConfig) => {
  if (isLogoutRequested()) return requestConfig;

  Object.assign(
    // @ts-ignore
    requestConfig.headers,
    getBackendHeadersToAdd()
  );
  // if data is undefined, axios removes Content-Type header
  if (requestConfig.data === undefined) {
    // eslint-disable-next-line no-param-reassign
    requestConfig.data = {};
  }

  return requestConfig;
});

carriyoClient.interceptors.request.use(proactiveLogoutInterceptor);
carriyoClient.interceptors.response.use(identity, reactiveLogoutInterceptor);

const middlewareInterceptor = (requestConfig) => {
  if (isLogoutRequested()) return requestConfig;
  Object.assign(requestConfig.headers, getBackendHeadersToAdd());
  return requestConfig;
};

const middlewareBaseUrl = /** @type {string}*/ (
  // @ts-ignore
  import.meta.env.VITE_APP_MIDDLEWARE_BASE_URL
);

export const nodeMiddlewareClient = axios.create({
  baseURL: middlewareBaseUrl,
  headers: {
    'content-type': 'application/json',
    'user-tz': timeZone,
  },
  paramsSerializer: (ps) => qs.stringify(ps, { arrayFormat: 'repeat' }),
});

nodeMiddlewareClient.interceptors.request.use(middlewareInterceptor);
nodeMiddlewareClient.interceptors.request.use(proactiveLogoutInterceptor);
nodeMiddlewareClient.interceptors.response.use(
  identity,
  reactiveLogoutInterceptor
);

const trackingBaseUrl = /** @type {string}*/ (
  // @ts-ignore
  import.meta.env.VITE_APP_TRACKING_BASE_URL
);

export const nodeMiddlewareIOClient = axios.create({
  baseURL: trackingBaseUrl,
  headers: {
    'content-type': 'application/json',
    'user-tz': timeZone,
  },
  paramsSerializer: (ps) => qs.stringify(ps, { arrayFormat: 'repeat' }),
});

nodeMiddlewareIOClient.interceptors.request.use(middlewareInterceptor);
nodeMiddlewareIOClient.interceptors.request.use(proactiveLogoutInterceptor);
nodeMiddlewareIOClient.interceptors.response.use(
  identity,
  reactiveLogoutInterceptor
);

export const accountServiceClient = axios.create({
  baseURL: `${middlewareBaseUrl}/account`,
  // baseURL: 'http://localhost:5000/account',
  headers: {
    'content-type': 'application/json',
    'user-tz': timeZone,
  },
  paramsSerializer: (ps) => qs.stringify(ps, { arrayFormat: 'repeat' }),
});

accountServiceClient.interceptors.request.use(middlewareInterceptor);
accountServiceClient.interceptors.request.use(proactiveLogoutInterceptor);
accountServiceClient.interceptors.response.use(
  identity,
  reactiveLogoutInterceptor
);
export const log = async (payload) => {
  try {
    const { status } = await nodeMiddlewareClient.post(browserLogPath, payload);
    if (status === 200) return true;
  } catch (err) {
    // DO NOT log with console.error, to prevent infinite cyclic logging
    // eslint-disable-next-line no-console, no-underscore-dangle
    // @ts-ignore
    (console._error || console.debug)(err);
    return /** @type {Error} */ (err);
  }
  return false;
};
